var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('redeem_reward.head')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('redeem_reward.head_list'))+" ")]),_c('v-card-actions',[_c('v-row',{staticClass:"d-flex align-center justify-end",attrs:{"no-gutters":""}},[(_vm.canCreate)?_c('v-btn',{staticStyle:{"margin-top":"1px","margin":"right: 5px"},attrs:{"color":"primary"},on:{"click":_vm.linkToAdd}},[_vm._v("+ "+_vm._s(_vm.$t('redeem_reward.create')))]):_vm._e(),_c('div'),(_vm.canCreate)?_c('v-btn',{staticStyle:{"margin-top":"1px","margin-left":"5px"},attrs:{"color":"primary"},on:{"click":_vm.linkToHistory}},[_vm._v(" "+_vm._s(_vm.$t('redeem_reward.history')))]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.value,"no-data-text":_vm.$t('not_found'),"no-results-text":_vm.$t('not_found'),"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"page":_vm.pagination.page,"footer-props":{
            'items-per-page-options': [10, 25, 30, 40, 50],
          },"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
          var item = ref.item;
return [(item.imageUrl != null)?_c('img',{attrs:{"src":item.imageUrl,"width":"50","height":"50"}}):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"color":item.status === 'true' ? 'success' : 'error'},on:{"click":function($event){return _vm.updateStatus(item)}}},[_vm._v(" "+_vm._s(item.status === "true" ? _vm.$t('redeem_reward.enabled') : _vm.$t('redeem_reward.disabled'))+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"color":"#FF0000"},on:{"click":function($event){return _vm.deleteRedeemReward(item)}}},[_vm._v(" ลบ ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }