<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('redeem_reward.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('redeem_reward.head_list') }} </v-card-title>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
            <v-btn v-if="canCreate" color="primary" @click="linkToAdd" style="margin-top: 1px;margin: right: 5px;">+ {{
              $t('redeem_reward.create')
            }}</v-btn>
            <div> </div>
            <v-btn v-if="canCreate" color="primary" @click="linkToHistory" style="margin-top: 1px;margin-left: 5px;"> {{
              $t('redeem_reward.history')
            }}</v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.imageUrl`]="{ item }">
              <img v-bind:src=item.imageUrl width="50" height="50" v-if="item.imageUrl != null" />
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-btn :color="item.status === 'true' ? 'success' : 'error'" class="white--text text-capitalize"
                @click="updateStatus(item)">
                {{ item.status === "true" ? $t('redeem_reward.enabled') : $t('redeem_reward.disabled') }}
              </v-btn>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="#FF0000" class="white--text text-capitalize" @click="deleteRedeemReward(item)">
                ลบ
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      headers: [
        {
          text: this.$t('redeem_reward.header1'),
          value: 'name',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header2'),
          value: 'detail',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header3'),
          value: 'imageUrl',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header4'),
          value: 'rewardType',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header5'),
          value: 'rewardAmount',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header13'),
          value: 'isPartner',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header6'),
          value: 'turn',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header8'),
          value: 'redeemAmount',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header7'),
          value: 'redeemType',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header9'),
          value: 'rewardCount',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header10'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.header11'),
          value: 'action',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    // this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.getRedeemRewardData(newValue.page, newValue.itemsPerPage)
          this.value = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Redeem Reward',
              url: window.location.href,
              detail: 'ระบบ Redeem Reward',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getRedeemRewardData(page, row) {
      try {
        return await this.$store.dispatch('getRedeemReward', `page=${page}&rows=${row}`);
      } catch (e) { console.log("Error " + e); }

    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'redeemReward')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true

      }
    },
    async updateStatus(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("redeem_reward.alert_update_confirm1"),
          text: this.$t("redeem_reward.alert_update_confirm2") + " '" + item.name + "' " + this.$t("redeem_reward.to") + ((item.status == "true") ? this.$t("redeem_reward.alert_update_disabled") : this.$t("redeem_reward.alert_update_enabled")),
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async action => {
          if (action.isConfirmed) {
            item.status = (item.status == "true") ? "false" : "true";
            await this.$store.dispatch('updateRedeemRewardStatus', item);
          }
        })
    },
    async deleteRedeemReward(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("redeem_reward.alert_delete_confirm1"),
          text: this.$t("redeem_reward.alert_delete_confirm2") + " '" + item.name + "'",
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async action => {
          if (action.isConfirmed) {
            await this.$store.dispatch('removeRedeemReward', item.id);
            this.$router.go()
          }
        })
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    linkToAdd() {
      this.$router.push({ name: 'redeemreward-add' })
    },
    linkToHistory() {
      this.$router.push({ name: 'redeemreward-history' })
    },
  },
}
</script>
